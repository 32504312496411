.app {
  top: 0;
  bottom: 0;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  background-image:linear-gradient(rgba(45, 165, 64, 0.5), rgba(45, 165, 64, 0.5)),url('../components/images/connection.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}


.login-form { 
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* size: 100px; */
}




.Auth-form-container {
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25vw;
  height: 45vh;
}

.Auth-form {
  top: 0;
width: 100%;
height: 100%;
/* box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px; */
/* padding-top: 30px;
padding-bottom: 20px;
border-radius: 8px; */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
}

.Auth-form-content {
display: flex;
flex-direction: column;
/* justify-content: center; */
align-items: center;
background-color: white;
gap: 10%;
}

.Auth-form-title {
text-align: center;
/* margin-bottom: 1em; */
font-size: 2rem;
color: #2da540;
font-weight: 800;
}

.label {
font-size: 1rem;
font-weight: 600;
color: #131c85;
text-align: left;
margin-top: 1.5em;
margin-bottom: 0.5rem;
left: 0;
}

.input1{
  border: none;
  outline: none;
  font-size: 1rem;
}

.error {
  color: red;
  font-size: 12px;
}

/* .label{
  font-size: 14px;
font-weight: 600;
color: rgb(34, 34, 34);

}  */

.colorbtn1 { 
    background-color: #2da540;
    font-size: 1.3rem;
    font-weight: bold;
    color: rgb(241, 239, 239);
    width: 100%;   
    border-radius: 0.3rem;
    border: none;
}
.champs{
  border: 1px solid #2da540; 
  height: 3rem; 
  border-radius: 0.3rem; 
  border-left: 0;
}
.content-icone{
  height: 3rem; 
  width: 2.5rem; 
  border-radius: 0.3rem 0rem 0rem 0.3rem; 
  background-color: #2da540;
}
.icone{
  width: 1.875rem;
  height: 1.875rem;
}
.icone1{
  cursor: pointer;
  background-color: #2da540;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 2.5rem;
  border-radius: 0rem 0.3rem 0.3rem 0rem;
}
.text-danger{
  font-size: 1rem; 
  text-align: left
}
.connect{
  height: 3rem;
  margin-top: 4rem;
}
.retour{
  top: 0; 
  left: 0; 
  position: fixed; 
  color: #2da540; 
  border: none; 
  background-color: white;
}
.iconRetour{
  height: 3.125rem;
  width: 3.125rem;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
  .Auth-form-content {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-color: white;
    gap: 10%;
    width: 90%;
    }
  .Auth-form-title {
    text-align: center;
    /* margin-bottom: 1em; */
    font-size: 1.5rem;
    color: #2da540;
    font-weight: 800;
    }
    .label {
      font-size: 0.75rem;
      font-weight: 600;
      color: #2da540;
      text-align: left;
      margin-top: 1em;
      margin-bottom: 0.25rem;
      left: 0;
      } 
      .champs{
        border: 1px solid #2da540; 
        height: 2.2rem; 
        border-radius: 0.3rem; 
        border-left: 0;
        width: 100%;
      }
      .content-icone{
        height: 2.2rem; 
        width: 2rem; 
        border-radius: 0.3rem 0rem 0rem 0.3rem; 
        background-color: #2da540;
      }
      .icone{
        width: 1.2rem;
        height: 1.2rem;
      }
      .icone1{
        cursor: pointer;
        background-color: #2da540;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.2rem;
        width: 2rem;
        border-radius: 0rem 0.3rem 0.3rem 0rem;
      }
      .input1{
        border: none;
        outline: none;
        height: 2rem;
        width: 100%;
      }
      .text-danger{
        font-size: 0.7rem; 
        text-align: left
      }
      .colorbtn1 { 
        background-color: #2da540;
        font-size: 1rem;
        font-weight: bold;
        color: rgb(241, 239, 239);
        width: 100%;   
        border-radius: 0.3rem;
        border: none;
      }
      .connect{
        height: 2.2rem;
        margin-top: 2.5rem;
      }
      .iconRetour{
        height: 2rem;
        width: 2rem;
      }
}

@media (max-width: 960px) {
  .login-form {
    flex: 100%;
    flex-direction: row;
  }
}