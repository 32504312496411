.navbar1 {
  background-color: #131c85;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  height: 13vh;
  width: 100%;
  z-index: 100;
}

.nav-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  max-width: 1000px;
 position: sticky;
}

.nav-logo1 {
  color: white;
  align-items: center;
  margin-right: 50px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  flex-grow: 1;
}

.nav-menu1 {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: -7rem;
}

.nav-links1 {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.nav-links1:hover {
  background-color:#fff; /*will change background-color of element on hover */
  color:#195329; /*will change color of text within the element on hover */
  }

.nav1-links1 {
  color: #fff;
  text-decoration: none;
padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code1 {
  margin-left: 1rem;
}

.nav-item1 {
  line-height: 30px;
  margin-right: 0.5rem;
}

.nav-item1:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item1:hover:after {
  width: 100%;
  background:#195329;
}

.nav-item1 .active {
  color: #121d15;
  border: 1px solid white;
}

.nav-icon1 {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background:  #0f3601;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #ebf1f3 ;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color:  white;
  }
}

.iconaphrc{ 
  width: 60px;
  height: 50px;
  vertical-align: middle;
    border-radius: 50%;
    margin-left: 13px;
   
}
.iconensa{ 
  width: 60px;
  height: 50px;
  vertical-align: middle;
    border-radius: 50%;
    margin-left: 6px;
   
}
.iconigt{ 
  width: 80px;
  height: 50px;
  vertical-align: middle;
    border-radius: 50%;
    margin-left: 2px;
   
}

#nav-dropdown{
  color: white;
  /* font-weight: bold; */

}
.navConnxion{
  text-decoration:none;
}