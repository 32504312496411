
.containtable {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.new-table th {
    border :0.2rem solid #000; 
    text-align: center;
    padding: 0.625rem 3.125rem;
    font-size:1.5rem ; 
    color:#208001;
}
.new-table td { 
    border :0.2rem solid #000; 
    text-align: center;
    padding: 0.625rem 3.125rem;
    font-size:1.25rem ;
    color: #000;
}
 
.new-table { 
    border-collapse: collapse;
    width: 80%;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
    .new-table th {
        border :0.2rem solid #000; 
        text-align: center;
        padding: 0.625rem 3.125rem;
        font-size:1.1rem ; 
        color:#2da540;
    }
    .new-table td { 
        border :0.2rem solid #000; 
        text-align: center;
        padding: 0.625rem 3.125rem;
        font-size: 0.9rem ;
        color: #000;
    }
}

@media (max-width: 868px) { 
    .containtable {
        margin-top: 5%;
        justify-content: center;
        align-items: center;
      
    }
    
    td { 
        font-size:15px ;
    }
    .new-table th ,.new-table td { 
        border :1px solid #258BAF; 
        text-align: center;
        padding: 1px 5px;
        font-size:10px ;
        color: black;
    }
    .new-table th { 
        border :1px solid #258BAF; 
        text-align: center;
        padding: 1px 5px;
        font-size:15px ;
        color:#258BAF;
    }
}