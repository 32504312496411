.input_News {
  width: 100%;
  max-width: 55%;
  min-height: 5vh;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  outline: none;
  border-radius: 0.2rem;
  color: #5b625b;
}
.textarea{
  color: #5b625b;
  font-size: 1rem;
  outline: none;
  border-radius: 0.2rem;
  width: 100%; 
  max-width: 55%;
  min-height: 25vh;
}

.button_News {
  width: 24vh;
  padding: 0.2rem;
  height: 6vh;
  outline: none;
  border: none;
  border-radius: 0.3rem;
  color: white;
  margin-top: 0.5rem;
  background-color: #2da540;
  font-weight: bold;
}
.design {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* .label_News {
  width: 61vh;
  font-size: 40px;
  color:white;
}
.label_News p{

  font-size: 17px;
 
} */
.boxNewsletter {
  padding: 1.2rem;
  /* margin-top: 50px; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-image:linear-gradient(rgba(19, 28, 133, 0.3), rgba(19, 28, 133, 0.3)),url('../components/images/contactez.avif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  backdrop-filter: blur('10px');
}

.en-tête{
  font-weight: bold; 
  color: white; 
  font-size: 1rem;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
  .en-tête{
    font-weight: bold; 
    color: white; 
    font-size: 0.8rem;
  }
  .input_News {
    width: 100%;
    max-width: 55%;
    min-height: 3vh;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    outline: none;
    border-radius: 0.2rem;
    color: #5b625b;
  }
  .textarea{
    color: #5b625b;
    font-size: 0.8rem;
    outline: none;
    border-radius: 0.2rem;
    width: 100%; 
    max-width: 55%;
    min-height: 20vh;
  }
  .button_News {
    width: 13vw;
    padding: 0.2rem;
    height: 5vh;
    outline: none;
    border: none;
    border-radius: 0.3rem;
    color: white;
    margin-top: 0.5rem;
    background-color: #2da540;
    font-weight: bold;
    font-size: 1rem;
  }
}

@media (max-width: 960px) {
  .boxNewsletter {
    width: auto;
  }
  .label_News {
    width: 100%;
    color: black;
  }
  textarea{
    width: 100%;
  }
  .button_News, .input_News{
    width: 100%;
  }
}
/* form {
  margin-top: 20px;
}

.formRow {
  margin: 10px 0 20px 0;
}

.formInput {
  padding: 15px 10px;
}

.errorMessage {
  color: #f45532;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.submit-btn {
  padding: 15px 50px;
  border-radius: 10px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .submit-message {
    width: 125px;
    margin-left: 200px;
  }} */