
.footer{
	background-color: #3a3837;
	height: 12vh;
	padding-top: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5rem; 
	position: relative; 
	bottom: 0;
}
.contaiBox{
	width: 80%;
	margin: auto;
}
.box {
	padding: 10px 10px;
	background-color: #258BAF;
	position: flex;
	bottom: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
}
.lastLine{
	/* text-align: center; */
	color: white;
	font-size: 1.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
	.lastLine{
		/* text-align: center; */
		color: white;
		font-size: 0.8rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.footer{
		background-color: #3a3837;
		height: 12vh;
		padding-top: 1.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 3rem; 
		position: relative; 
		bottom: 0;
	}
}

@media (max-width: 960px) {
	.box {
		flex-direction: column;
		flex: 100%;
	}

	.copyright {
		flex: 30%
	}

	.h5 {
		font-weight: 10px;
		font-size: 10px;
	}
	

}

.copyright {
	flex: 30%;
	
}

.logopartenaire {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;


}

.logopartenaire1 {
	flex: 30%;
	align-items: center;
	margin-left: 40%;
	
}
.copyright, ul{
	padding: 0;
	margin: 0px;

}
.copyright, ul, li{
	list-style: none;
	color: white;
	
}
.copyright  ul  li{
	letter-spacing: 2px;
	color: white;
	cursor: default;
}
.copyright  ul  .contact{
	
	cursor: pointer;
}
.copyright  ul  li a{
	
	text-decoration: none;
	height: inherit;
	width: inherit;
	color: white;
}.copyright  ul  li a:hover{
	border-bottom: 2px solid white;

}
.ulContainer{
	gap: 5px;
	margin-top: 14px;
	display: flex;
	
}
.ulContainer, .linkcontact
{
	padding-left: 30%;
}

.linkcontact{
	font-size: 19px;
	font-weight: bold;

}