@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
.contact3 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
  max-width: 70%;
  margin-left: 15%;
}

.h1contact { 
  color: #258BAF;
  background-color: #FFF;
}


.contact3 h2,
.contact3 h3,
.contact3 h4,
.contact3 h5,
.contact3 h6 {
  color:#316685;
}


.contact3 .font-weight-medium {
  font-weight: 500;
}

.contact3 .card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.contact3 .btn-danger-gradiant {
  background-color: #316685;;
}

.contact3 .btn-danger-gradiant:hover {
  background-color: #258BAF;
}