@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;');
*{
    margin: 0px;
    padding: 0px;
    /* box-sizing: border-box; */
    font-family: 'Roboto',sans-serif;
}
.main1 { 
    flex:100%;
}
.blog-section{
    width: 100%;
}
.blog-section .section-content{
    width: 85%;
    margin:20px auto;
}
.blog-section .section-content .title{
    width: 60%;
    text-align: center;
    margin: auto;
}
.blog-section .section-content .title h2{
    font-size: 40px;
    color: #23d25d;
    text-transform: uppercase;

}
.blog-section .section-content .title p{
    font-size: 18px;
    color: #6a6a6a;
    margin-top: 20px;
}
.blog-section .section-content .cards{
    display:grid ;
    grid-template-columns: repeat(3,1fr);
    grid-gap:50px;
    margin: 25px auto;
}
.blog-section .section-content .cards .card{
   width: 100%;
   background-color: #f7f7f7;
   position: relative;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.blog-section .section-content .cards .card img{
    width: 100%;
    height: auto;


}
.blog-section .section-content .cards .card .article{
    padding: 15px 20px;
}
.blog-section .section-content .cards .card .article h4{
    font-size: 24px;
    margin: 10px 0px;
    color: #6a6a6a;
}
.blog-section .section-content .cards .card button{
    text-decoration: none;
    display: inline-block;
    background-color: #258BAF;
    padding:8px 15px ;
    margin-left:20px ;
    color: #fff;
    margin-bottom: 20px;
    cursor: pointer;

    

}

.blog-section .section-content .cards .card .posted-date p{
    font-weight: 600px;
    position: absolute;
    top:0;
    left: 0;
    display: inline-block;
    background-color: #258BAF;
    color: #fff;
    padding:6px 15px ;
}
.carte1_senegal{
    width: 500px;
  height:00px;
  background: red;
  transition: width 2s, height 2s, transform 2s;
  cursor: pointer;
   
}
.carte1_senegal:hover {
    
    transform: rotate(180deg);
  }
.carte_senegal{
    /* width: 500px; */
    margin-left: 500px;
    height: 400px;
}
.heading {
    margin: 30px 0;
    background-image: url("./title_pattern.png");
    height: 35px;
    background-position: center;
  }
  .heading h6 {
    font-weight: 400;
    background: #258BAF;
    color: #fff;
    height: 35px;
    width: 130px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
  .heading1 {
    margin: 30px 0;
    background-image: url("./title_pattern.png");
    height: 35px;
    background-position: center;
    margin-top: 0px;
  }
  .heading1 h6 {
    font-weight: 400;
    background: #258BAF;
    color: #fff;
    height: 35px;
    width: 130px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
  .avatar {
    vertical-align: middle;
   
    height: 155px;
    border-radius: 50%;
    width:160px;
    box-shadow: 0 0 0 4px #fff;
    margin:-1.3em;
  }
  .avatar1{
    vertical-align: middle;
   
    height: 140px;
    border-radius: 50%;
    width:200px;
    box-shadow: 0 0 0 4px #fff;
    margin:-0.5em;
   
  }
  .avatar2{
    vertical-align: middle;
   
    height: 140px;
    border-radius: 50%;
    width:150px;
    box-shadow: 0 0 0 4px #fff;
   
   
  }
  .FlexContainer {
    margin-left: -4px;
    box-shadow: 0 0 0 4px #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    
}
.container {
    display: flex;
    flex-direction: column;
}

.container__main {
    /* Take the remaining height */
    flex-grow: 1;

    /* Layout the left sidebar, main content and right sidebar */
    display: flex;
    flex-direction: row;
}

.container__left {
    width: 25%;
}

.container__middle {
    /* Take the remaining width */
    flex-grow: 1;
}

.container__right {
    width: 20%;
}
.carouselimg{ 
    height:auto;
    width: auto;
} 
.carouselicon{ 
    height: 3.125rem;
    width: 3.125rem;
    align-self: center;
} 
.carouselcontain{
    display: flex;
    flex-direction:row;
    justify-content: center;
    /* background-color:#f7e922; */
    align-items: center;
    gap: 1rem;
    height: 55vh;
    width: 100%;
}
.carouseldiv{ 
    display: flex;
    justify-content: center;
    align-items: center;
    flex:40%;
    width: 100%;
    height: 100%;
    /* background-color:#208001; */
    /* background-color:#f7e922; */
    border: 1.5px solid #208001;
}
.carouseldive{ 
    flex:50%;
    width: 400px;
    height: 400px;
    background-image: url("../components/images/imageDashbord.jpeg");
    background-color:white;
 
}

.roomfac {
    display: flex;
    width: 100%;
    max-width: 60%;
    margin: 0 auto;
    justify-content: center;
}

.shadow-lg{
    background-color: #258BAF;  
}
  
.textcarousel{ 
    color: #f7f7f7;
    padding: 10px ;
    text-align: center;
    font-style: italic;
    max-height: 370px;


}
.cardrowdomain{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.letter{
    height: 54.5vh;
    width: 50%; 
    border: 1px solid #208001;
}

.num{
    margin-left: 25%;
    display: flex; 
    justify-content: left; 
    align-items:center; 
    margin-top: 3rem;
    gap: 1rem;
}

.carous{
    color: #fff;
    text-align: center;
}

.contain{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: 2rem;
}

.sous-contain{
    display: flex;
    gap: 0.5rem;
}

.span1{
    font-size: 1rem;
    font-weight: bold;
    color: #131c85;
}

.span2{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.communeDashboard{
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: center;
    padding-top: 0;
    gap: 2%;
}
.communeDashboard1{
    display: flex;
    flex-direction: column;
    width: 25%;
    border: 1px solid #208001;
    justify-content: space-between;
}
.sous-communeDashboard1{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.communeDashboard2{
    width: 75%;
}

.commune{
    width: 91.5%;
    height: 70vh;
    margin-bottom: 4rem;
}

.labelRadio{
    cursor: pointer;
    text-align: start;
    font-weight: lighter;
    font-size: 1rem;
}

.inputRadio{
    margin: 1.2rem 0.8rem 1.2rem 0.8rem;
}

.sous-titre{
    font-weight: bold; 
    background-color: #208001;
    padding: 1rem;
    color: #fff;
}

.gros-titre{
    font-weight: bold; 
    background-color: #208001; 
    color:white;
}

.sous-titre1{
    font-weight: bold; 
    background-color: #208001; 
    font-size: 2rem; 
    padding: 1rem;
    color: #fff; 
    display: flex; 
    justify-content: center;
}

.icon{
    width: 2.5rem;
    height: 2.5rem;
    color: #208001;
}

.contact{
    font-weight: bold; 
    color: #208001;
}

.partenaire{
    margin-top: 1.5rem;
    width: 91.5%; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}
.tabES{
    height: 85vh;
}
.btnIcon{
    width: 8rem;
}
.titreES{
    font-size: 1.4rem;
}
.card { 
    flex:50%;
    margin-bottom: 0.125rem;
    margin-right: 1.25rem;
    
    }
.part-newletter{
    width: 100%;
    display:flex;
    justify-content: center; 
    align-items: center; 
    margin-bottom: 5rem; 
    margin-top: 5rem;
}
.part-newletter-1{
    width: 91.5%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    gap: 1rem;
}
.part-partenaire{
    width: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
}
.part-partenaire-1{
    width: 91.5%; 
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    gap: 1rem;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
    .carouselcontain{
        display: flex;
        flex-direction:row;
        justify-content: center;
        /* background-color:#f7e922; */
        align-items: center;
        gap: 0.5rem;
        height: 55vh;
        width: 100%;
        margin-bottom: 4rem;
    }
    .span1{
        font-size: 0.8rem;
        font-weight: bold;
        color: #131c85;
    }
    .letter{
        height: 54.5vh;
        width: 50%; 
    }
    .labelRadio{
        cursor: pointer;
        text-align: start;
        font-weight: lighter;
        font-size: 0.7rem;
    }
    .inputRadio{
        margin: 0.8rem 0.4rem 0.8rem 0.4rem;
    }
    .sous-titre{
        font-weight: bold; 
        background-color: #208001;
        padding: 1rem;
        color: #fff;
        font-size: 1.2rem;
    }
    .commune{
        width: 91.5%;
        height: 70vh;
        margin-bottom: 2.5rem;
    }
    .gros-titre{
        font-weight: bold; 
        background-color: #208001; 
        color:white;
        font-size: 1.6rem;
    }
    .sous-titre1{
        font-weight: bold; 
        background-color: #208001; 
        font-size: 1.5rem; 
        padding: 0.5rem;
        color: #fff; 
        display: flex; 
        justify-content: center;
    }
    .num{
        margin-left: 25%;
    }
    .contact{
        font-weight: bold; 
        color: #208001;
        font-size: 1.1rem;
    }
    .icon{
        width: 1.5rem;
        height: 1.5rem;
        color: #208001;
    }
    .partenaire{
        margin-top: 1rem;
        width: 65%; 
        display: flex; 
        justify-content: center; 
        align-items: center;
    }
    .tabES{
        height: 70vh;
    }
    .card { 
        flex: 25%;
        margin-bottom: 0.125rem;
        margin-right: 1.25rem;
    }
    .carouselicon{ 
        height: 1.75rem;
        width: 1.75rem;
        align-self: center;
    }
    .btnIcon{
        width: 6rem;
    }
    .titreES{
        font-size: 0.9rem;
    } 
}

@media (max-width: 960px) { 
    .carouselcontain{
        display: block;
        justify-content: center;
        /* background-color:#f7e922; */
        align-items: center;
        height: 100%;
        width: 100%;
    
    }
    .communeinfo{ 
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        height:100%;
        width:100%;
    }
    .infoscommune {
        flex: 50%;
        
    }
    .mapscommune{
        flex:50%;
    }
    
    .carouseldiv{ 
        display: flex;
        justify-content: center;
        align-items: center;
        flex:100%;
        width: 100%;
        height: 30vh;
        /* background-color:#208001; */
        /* background-color:#f7e922; */
        border: 0.5px solid #208001;
    }
    .carouselimg{ 
        height: 400px;
        width: 400px;
    } 
    
    .textcarousel{ 
        color: #f7f7f7;
        padding: 6px ;
        font-style: italic;
        font-size: 9px;
    
    }
    .cardDomain { 
        display: flex;
        /* flex:40%; */
        justify-content: center;
        flex-direction:column;
        align-items: center;
        height:100%;
        width:100%;
        background-color:#208001;
        background-image:linear-gradient(rgba(32, 128, 1, 0.7), rgba(32, 128, 1, 0.7)),url('../components/images/boulel.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .contain{
        width: 100%;
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
        /* gap: 0.5rem; */
        /* margin: 2rem; */
    }
    
    .span1{
        font-size: 0.4rem;
        font-weight: bold;
        color: #131c85;
    }
    
    .span2{
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
    }
    .letter{
        height: auto;
        width: 100%; 
        border: 1px solid #208001;
        margin-bottom: 0.5rem;
    }
    .num{
        margin-left: 20%;
        display: flex; 
        justify-content: left; 
        align-items:center; 
        margin-top: 1rem;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    .part-newletter-1{
        width: 91.5%; 
        display: block; 
        justify-content: center; 
        align-items: center; 
        gap: 1rem;
    }
    .partenaire{
        margin-top: 0rem;
        width: 91.5%; 
        display: block; 
        justify-content: center; 
        align-items: center;
    }
    .communeDashboard{
        width: 100%;
        height: 100%;
        display: block;
        justify-items: center;
        padding-top: 0;
        gap: 2%;
    }
    .communeDashboard1{
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 1px solid #208001;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }
    .communeDashboard2{
        width: 100%;
        height: 38vh;
    }
    .gros-titre{
        font-weight: bold; 
        background-color: #208001; 
        color:white;
        font-size: 1rem;
    }
    .cardrowdomain{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .card { 
        flex: 15%;
        margin-bottom: 0.125rem;
        margin-right: 1.25rem;
    }
    .carouselicon{ 
        height: 0.9rem;
        width: 0.9rem;
        align-self: center;
    }
    .btnIcon{
        width: 4rem;
    }
    .titreES{
        font-size: 0.5rem;
    } 
    .contain{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin: 1.5rem 0rem 1.5rem 0rem;
    }
    .sous-contain{
        display: flex;
        gap: 0.5rem;
    }
    .labelRadio{
        cursor: pointer;
        text-align: start;
        font-weight: lighter;
        font-size: 0.5rem;
    }
    
    .inputRadio{
        margin: 1.2rem 0.8rem 1.2rem 0.8rem;
    }
    .cardDomain{
        margin-bottom: 0.5rem;
    }
    .sous-titre{
        font-weight: bold; 
        background-color: #208001;
        padding: 0.5rem;
        color: #fff;
        font-size: 0.8rem;   
    }
    .tabES{
        height: 45vh;
    }
    .part-newletter{
        width: 100%;
        display:flex;
        justify-content: center; 
        align-items: center; 
        margin-bottom: 1rem; 
        margin-top: 1rem;
    }
    .sous-titre1{
        font-weight: bold; 
        background-color: #208001; 
        font-size: 1rem; 
        padding: 0.5rem;
        color: #fff; 
        display: flex; 
        justify-content: center;
    }
    .icon{
        width: 1.5rem;
        height: 1.5rem;
        color: #208001;
    }
    .contact{
        font-weight: bold; 
        color: #208001;
        font-size: 0.8rem;
    }
    .part-partenaire-1{
        width: 91.5%; 
        display: flex; 
        flex-direction: column;
        justify-content: center; 
        align-items: center; 
        gap: 1rem;
    }
}

.space { 
    height: 10px;
}

/* .backcontain{
    background-image: url("../components//images/photo_atelier_5.jpeg");
    height: 100%;
    background-repeat: no-repeat;

 
    display: grid;
    place-items: center;
} */

.cardDomain { 
    display: flex;
    flex:40%;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    height:100%;
    width:100%;
    background-color:#208001;
    background-image:linear-gradient(rgba(32, 128, 1, 0.7), rgba(32, 128, 1, 0.7)),url('../components/images/boulel.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.communeinfo{ 
display:flex;
flex-direction:column;
justify-content: center;
align-items: center;


}

.mapscommune{

flex:50%;

}
.infoscommune {
    flex:50%;
}

.arrow{ 
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    background-color: #208001;
    color: aliceblue;
    margin-bottom: 1.2rem;
    border-radius: 1rem;
}
.contain-titre{
    width: 91.5%;
}