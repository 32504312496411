.navbar {
  background-color: #2da540;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  height: 13vh;
  width: 100%;
  z-index: 100;
}

.logo{
  display: flex;
  justify-content: end;
  align-self: center;
  height: auto;
  width: 25%;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
}

.nav-logo {
  height: auto;
  width: 20%;
  border-radius: 5%;
}

.nav-menu {
  /* list-style: none; */
  /* margin-right: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.nav-links {
  display: flex;
  justify-content: center;
  align-self: center;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.nav1-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 1px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.conn{
  margin-left: 10rem;
}

.nav-item:hover {
  transform: scale(1.2);
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: white;
}

.nav-item .active {
  color: #fff;
  background-color: #208001;
  border: 1px solid white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.nav-item .active:hover{
  color: #208001;
  background-color: #fff;
  transform: scale(1.2);
}

.nav-icon {
  display: none;
}

.connection{
  display:flex;
  justify-content:center;
  align-Items:center;
  gap:0.5rem;
  background-color:#fff;
  border-radius:0.4rem;
  color: #208001;
  /* border:0.5px solid; */
}

.connection:hover{
  background-color:#208001;
  color: #fff;
}
.icones{
  height: 1.5rem;
  width: 1.5rem;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
  .navbar {
    background-color: #2da540;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: sticky;
    top: 0;
    height: 13vh;
    width: 100%;
    z-index: 100;
  }
  .logo{
    display: flex;
    justify-content: start;
    align-self: center;
    height: auto;
    width: 20%;
  }
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
  }
  .conn{
    margin-left: 2rem;
  }
  .connection{
    display:flex;
    justify-content:center;
    align-Items:center;
    gap:0.2rem;
    background-color:#fff;
    border-radius:0.4rem;
    /* border:0.5px solid; */
  }
  .icones{
    height: 1rem;
    width: 1rem;
  }
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background:  #a4c6d7;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #258BAF ;
    border: none;
  }
  .nav-links {
    color: #fff;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color:  white;
  }
}

.iconaphrc{ 
  width: 60px;
  height: 50px;
  vertical-align: middle;
    border-radius: 50%;
    margin-left: 13px;
   
}
.iconensa{ 
  width: 60px;
  height: 50px;
  vertical-align: middle;
    border-radius: 50%;
    margin-left: 6px;
   
}
.iconigt{ 
  width: 80px;
  height: 50px;
  vertical-align: middle;
    border-radius: 50%;
    margin-left: 2px;
   
}

#nav-dropdown{
  color: white;
  /* font-weight: bold; */

}
.navConnxion{
  text-decoration:none;
}