.monstyle1{
    min-height: 60vh;
    max-height: 60vh;
    max-width: 80vh;
    min-width: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.monstyle2{
    font-size: 1.8rem;
    color: white;
}

.monstyle3{
    display: block;
    width: 100%;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
    .monstyle2{
        font-size: 1.2rem;
    }
}

@media (max-width: 960px){
    .monstyle1{
        min-height: 30vh;
        max-height: 30vh;
        max-width: 45vh;
        min-width: 45vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .monstyle2{
        font-size: 0.8rem;
        text-align: justify;
    }
}