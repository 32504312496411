.c1{
    position:absolute;
    right:0px;
    top:0px; 
    width:40vh; 
    height:auto; 
    background-color:white;
    z-index:900;
    padding:10px;
}
.container_{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.elements{
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}
.title{
    min-width: 17vh;
    font-size: 14px;
}
.chechbox{
    padding: 0px;
    margin: 0px;
}
.check{
       display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
}



.color-elementaire{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #CB8427;
}
.color-secondaire{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #24AC20;
}
.color-lycee{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #635f7d;
}
.color-franco{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color:#704c4c;
}
.color-daara{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #40105E;
}
.color-poste{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #105E1A;
}



