.app-container {
    max-width: 220vh;
    margin: 0 auto;
    padding: 10px;
    font-family: Arial, sans-serif;
    max-height: 70vh;
  }
  

  
  .table-container {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .styled-table th, .styled-table td {
    padding: 8px 8px;
    text-align: left;
  }
  th, td{
    padding: 0px;
    margin: 0px;
  }
  .styled-table th {
    background-color: #D9ECD9; /*#083037;*/
    color: #289429;
  }
  
  .styled-table tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styled-table tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .styled-table tr:last-of-type {
    border-bottom: 2px solid #083037;
  }
  
  .styled-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .edit-btn {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .delete-btn {
    padding: 5px 10px;
    background-color: #FF6C4A;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-btn:hover {
    background-color: #45a049;
  }
  
  .delete-btn:hover {
    background-color: #FF5733;
  }
  
  .select_and_search{
    font-size:16px;
    max-width:25vh; 
    height:7vh; 
    border-radius:7px;
    outline:none;
    border:1px solid #4CAF50;
  }